/* new Login page */

.table-scroll {
  /*width:100%; */
  display: block;
  empty-cells: show;

  /* Decoration */
  border-spacing: 0;
  /* border: 1px solid; */
}

.table-scroll thead {
  background-color: #f1f1f1;
  position: relative;
  display: block;
  width: 100%;
  /* overflow-y: scroll; */
}

.table-scroll tbody {
  /* Position */
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  /* Decoration */
  /* border-top: 1px solid rgba(0,0,0,0.2); */
}

.table-scroll tr {
  width: 100%;
  display: flex;
}

.table-scroll td,
.table-scroll th {
  flex-basis: 100%;
  flex-grow: 2;
  display: block;
  padding: 1rem;
  text-align: left;
  border-bottom: none !important;
}

/* Other options */

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child {
  flex-basis: 20%;
  flex-grow: 1;
}

.table-scroll tbody tr:nth-child(2n) {
  background-color: rgba(130, 130, 170, 0.1);
}

.body-half-screen {
  max-height: 50vh;
}

.small-col {
  flex-basis: 10%;
}

.loginbody {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100vh;
  color: #323232;
}

.login-box-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.login-box {
  width: 550px;
  height: auto;
  background: #ffffff;
  text-align: center;
  box-shadow: 0 10px 50px rgb(0 0 0 / 16%), 0 7px 6px rgb(0 0 0 / 23%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.login-key {
  margin-bottom: 0px;
  width: 250px;
  margin: 0 auto;
}

.login-key img {
  width: 75%;
  margin: 0 auto;
}

.login-title {
  margin-top: 15px;
  text-align: center;
  font-size: 170%;
  font-weight: bold;
  color: #000000 !important;
}

.login-form {
  margin-top: 15px;
  text-align: left;
}

.login-box-sec .form-group>input[type="password"],
.login-box-sec .form-group>input[type="text"] {
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #2b2c5842;
  border-top: 0px;
  border-radius: 0px;
  outline: 0;
  margin-bottom: 3px;
  padding-left: 0px;
  color: #000000 !important;
  padding: 0 10px;
  height: 35px;
  font-size: 90%;
}

.login-box-sec .form-group .text-danger {
  font-size: 12px;
}

.login-box-sec .form-group {
  margin-bottom: 20px;
  outline: 0px;
}

.login-box-sec .form-group>.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #232446;
  outline: 0;
  background-color: #ffffff;
  color: #000000 !important;
}

.login-box-sec input:focus {
  outline: none;
  box-shadow: 0 0 0;
}

.login-box-sec label {
  margin-bottom: 0px;
}

.login-box-sec .form-control-label {
  font-size: 100%;
  color: #717171;
  font-weight: bold;
}

.btn-outline-primary {
  border: 2px solid #232446;
  color: #232446;
  border-radius: 0px;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.btnlogin {
  color: #232446;
  border-bottom: 2px solid #232446;
  border: 2px solid #232446;
}

.btn-outline-primary:hover {
  /* background-color: #ffffff; */
  /* color: #232446; */
  right: 0px;
}

.login-btn {
  float: left;
}

.login-button {
  padding-right: 0px;
  text-align: center;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-text {
  text-align: left;
  padding-left: 0px;
  color: #a2a4a4;
}

.login-text span {
  color: #5456a2;
}

.logo {
  width: 200px;
}

.loginbttm {
  padding: 0px;
}

.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner .menu-item .menu-link {
  text-decoration: none;
}

.center-sec-main {
  z-index: 0;
}

/* download button */
.download-btn {
  background: #3230a8;
  color: #fff;
  text-decoration: none;
  width: 260px;
  padding: 5px;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: 0 5px 25px rgba(1, 1, 1, 0.15);
}

.scroll_container {
  background-color: #000000;
  width: 421px;
  height: 420px;
  color: #999999;
  overflow: hidden;
  text-align: justify;
}

.scroll_content {
  position: relative;
  width: 100%;
  height: 450px;
  /* padding:20px 10px 20px 10px; */
  overflow-y: auto;
}

.scroll_content_LogReq {
  position: relative;
  width: 100%;
  height: 635px;
  overflow-y: auto !important;
}

.scroll_content_logs {
  position: relative;
  width: 100%;
  height: auto;
  /* padding:20px 10px 20px 10px; */
  /* overflow-y: auto; */
}

.top_bottom_mask {
  position: absolute;
  overflow: hidden;
  width: 17px;
  height: 10px;
}

.left_right_mask {
  position: absolute;
  width: 0px;
  border: 1px solid #000000;
}

.corner_top_mask,
.corner_bottom_mask {
  position: relative;
  left: -2px;
  border: 6px solid #000000;
  height: 20px;
  width: 13px;
  border-radius: 16px;
}

.corner_top_mask {
  top: -3px;
  left: -4px;
}

.corner_bottom_mask {
  top: -18px;
  left: -4px;
}

.download-btn:hover {
  background: #3230a8;
  color: #fff;
}

@media (min-width: 290px) and (max-width: 980.98px) {
  .login-box {
    width: 100%;
    height: auto;
    background: #ffffff;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

@media screen and (min-width: 1536px) and (min-height: 762.40px) {
  .laptop-width {
    min-width: -webkit-fill-available;
  }
}

@-webkit-keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3);
  }

  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3);
  }

  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

.line-scale-pulse-out-rapid>div {
  background-color: #fff;
  width: 5px;
  height: 23px;
  border-radius: 2px;
  margin: 2px;

  display: inline-block;
  vertical-align: middle;
  -webkit-animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
  animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.font-initial {
  font-style: initial;
}

.line-scale-pulse-out-rapid>div:nth-child(2),
.line-scale-pulse-out-rapid>div:nth-child(4) {
  -webkit-animation-delay: -0.25s !important;
  animation-delay: -0.25s !important;
}

.line-scale-pulse-out-rapid>div:nth-child(1),
.line-scale-pulse-out-rapid>div:nth-child(5) {
  -webkit-animation-delay: 0s !important;
  animation-delay: 0s !important;
}

/* page Loader */

#site {
  opacity: 0;
  transition: all 2s ease;
}

#preloader {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -(40px/2);
  margin-left: -(40px/2);
}

#preloader:before {
  content: "";
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  height: 100%;
  width: 100%;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  border: 1px solid #2fc394;
  border-top: 1px solid transparent;
  border-radius: 100%;
}

#preloader>.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 25px;
  width: 10.6px;
  margin-top: -12.5px;
  margin-left: -5.3px;
  -webkit-animation: wink 1s ease-in-out infinite alternate;
  animation: wink 1s ease-in-out infinite alternate;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.53;
  color: #697a8d !important;
  border: none;
}

.react-datetime-picker__inputGroup__input {
  color: #697a8d !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes wink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.btn-hover {
  min-width: 125px;
  font-size: 105%;
  letter-spacing: 0.7px;
  color: #2f3070ba;
  cursor: pointer;
  margin: 10px 20px 0;
  height: 42px;
  text-align: center;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  padding: 2px 25px 0;
}

.btn-hover-1 {
  min-width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #2f3070ba;
  cursor: pointer;
  /* margin: 20px; */
  height: 50px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 50px; */
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover-one {
  min-width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #232446;
  border: 2px solid #232446;
  /* cursor: pointer; */
  background-color: #fff;
  margin: 0px 9rem 0px;
  height: 50px;
  text-align: center;
  /* border: none; */
  background-size: 300% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  /* moz-transition: all .4s ease-in-out; */
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.name1 {
  margin: 0px 15px 5px 20px;
  /* margin-left: 15px; */
  /* margin-top: 14px; */
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-3 {
  /* background:#fff; */
  color: #232446;
  box-shadow: 0 9px 15px -10px #2f3070ba;
}

.btn-hover.color-3:hover {
  background: #e8e8e8;
  box-shadow: 0 9px 15px -10px #222351ba;
}

.btn-hover.color-3.active {
  background: #232446;
  color: #ffffff;
  box-shadow: 0 9px 15px -4px #2f3070ba;
}

.btn-hover.color-3.active:hover {
  background: #232353c7;
  box-shadow: 0 9px 20px -4px #2f3070ba;
}

.btn-hover-one {
  min-width: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #232446;
  border: 2px solid #232446;
  /* cursor: pointer; */
  background-color: #fff;
  height: 50px;
  text-align: center;
  /* border: none; */
  background-size: 300% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  /* moz-transition: all .4s ease-in-out; */
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin: 0 auto;
}

.name1 {
  margin: 0px 15px 5px 20px;
  /* margin-left: 15px; */
  /* margin-top: 14px; */
}

.btn-hover-1:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover-1:focus {
  outline: none;
}

.btn-hover-1.color-3 {
  /* background:#fff; */
  /* border-bottom:2px solid #232446; */
  /* border-right:2px solid #232446 */
  /* box-shadow: 0 9px 15px -10px #2f3070ba; */
  /* background: #fff; */
  /* border-bottom: 2px solid #232446; */
  /* border-right: 2px solid #232446; */
  /* box-shadow: 0 9px 15px -10px #2f3070ba; */
  font-weight: normal;
}

.btn-hover-1.color-3.active {
  /* background: #fff; */
  /* border-bottom: 5px solid #232446; */
  /* border-left:2px solid #232446;
  border-right:2px solid #232446;
  border-top:2px solid #232446; */
  /* color: #232446; */
  /* box-shadow: 0 9px 15px -4px #232446; */
  border-bottom: 3px solid #232446;
  color: #232446;
  /* box-shadow: 0 9px 15px -4px #232446; */
  font-weight: bold;
}

.new-border {
  border-bottom: 1px solid #2f3070ba;
}

.padding-remove {
  padding: 0;
}

/* Custom-card-title  */

.custom-card-body-section {
  padding: 0;
}

.main-card-sections {
  background: #fff;
}

.card-title-main {
  background: #232446;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
}

.card-title-main .titletexts {
  margin: 0;
  color: #fff;
  font-weight: bold;
  line-height: inherit;
}

.card-title-main .text-white .fa-refresh {
  cursor: pointer;
}

.inner-detail-sec {
  display: flex;
  align-items: flex-start !important;
  flex-wrap: unset;
}

.inner-detail-sec .detail-desc {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.inner-detail-sec .detail-desc button {
  margin-top: 0;
}

.inner-detail-sec .detail-title {
  font-size: 16px;
  font-weight: bold;
  min-width: 200px;
  margin-right: 5px;
  position: relative;
  display: inline-block;
}

.inner-detail-sec .detail-title::before {
  content: ":";
  position: absolute;
  right: 0;
}

/* 24-03-2023 changes and responsive */

.login-button .btn-outline-primary:hover {
  color: #fff;
  background-color: #232446 !important;
  border-color: #232446 !important;
}

.login-button .btn-outline-primary:focus {
  color: #fff;
  background-color: #232446 !important;
  border-color: #232446 !important;
  box-shadow: none !important;
}

.new-btn-width .btn-hover {
  min-width: 185px;
}

.new-btn-width .btn-hover {
  min-width: 185px;
}

.reqremove {
  display: none !important;
}

/* .PartielFile{
  position: relative;
    top: -3rem;
}
.PartielStage{
  position: relative;
    top: -4.4rem;
}
.PartielSubtype{
  position: relative;
    top: -3.2rem;
} */

.PartialRequestformFields {
  position: relative;
  top: 8rem;
}

.addnewuser-button {
  display: flex;
  justify-content: end;
}

.mb-one-rem {
  margin-top: 1.5rem;
}

.operationchackbox {
  margin-top: auto;
}

.url-links-tag {
  /* color: #3230a8 !important; */
  /* text-decoration: underline !important; */
}

.up_RPA_module:hover {
  color: #232446 !important;
}

.dashboard-progress-part {
  padding: 5px 0 5px 0 !important;
}

.dashboard-progress-part #progress-check .step .icon-round {
  height: 45px;
  width: 45px;
}

.dashboard-progress-part .main-timeline-sec {
  font-size: 14px;
}

.dashboard-progress-part #progress-check .step label {
  font-size: 15px;
}

.dashboard-progress-part #progress-check .step::after {
  top: 23px;
}

.dashboard-progress-part #progress-check .step.active::before {
  top: 23px;
}

/* ################  AddRequestOPList Style change  ################  */
.addROPList-tabs li {
  margin-top: 0px;
  margin-bottom: 0px;
}

.addROPList-tabs .btn-hover {
  height: 40px;
  min-width: 120px !important;
}

/* ################  Checkboxs style  ################  */
.custom-checkboxs {
  position: relative !important;
  margin-left: 0 !important;
}

/* ################  Card-body  style  ################  */
.custom-card-body {
  padding: 20px;
}

/* ################  Custom class  ################  */
.mb-30 {
  margin-bottom: 30px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

/* ################  Custom table  ################  */

/* .linkUpdate_bg {
  color: black;
  background-color: #f1f1f1;
}

.catalogUpdate_bg {
  color: black;
  background-color: #d7d7d773;
} */

/* responsive style start */
@media screen and (min-width: 1921px) {}

@media screen and (max-width: 1800px) {}

@media screen and (max-width: 1650px) {
  .imgcenter {
    margin-left: 14rem;
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 1535px) {}

@media screen and (max-width: 1500px) {
  .imgcenter {
    margin-left: 13rem;
    margin-top: 2.9rem;
  }
}

@media screen and (max-width: 1366px) {
  .imgcenter {
    margin-left: 11rem;
  }

  .name1 {
    margin: 0px 15px 5px 15px;
  }

  #progress-check {
    gap: 20px;
  }
}

@media screen and(max-width:1280) {
  .imgcenter {
    margin-left: 10rem;
  }
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 1024px) {
  .name1 {
    margin: 0px 10px 5px 10px;
  }

  #progress-check {
    column-gap: 32px;
  }

  #progress-check .step::after {
    width: 150% !important;
  }
}

@media screen and (max-width: 1023px) {}

@media screen and (max-width: 991px) {
  .name1 {
    margin: 0px 0px 5px 0px;
  }

  .imgcenter {
    margin-left: 18rem;
  }
}

@media screen and (max-width: 900px) {
  .imgcenter {
    margin-left: 16rem;
  }

  .form-input-position {
    position: unset !important;
  }
}

@media screen and (max-width: 820px) {
  .imgcenter {
    margin-left: 14rem;
  }

  .mb-one-rem {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .imgcenter {
    margin-left: 35rem;
  }

  .mb-one-rem {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .imgcenter {
    margin-left: 28rem;
  }
}

@media screen and (max-width: 585px) {
  .imgcenter {
    margin-left: 25rem;
  }

  .drag-drop-sec .wrapper .inner-part {
    display: block !important;
  }

  .drag-drop-sec .wrapper .inner-part .left-section {
    width: 100% !important;
    margin-bottom: 25px !important;
  }

  .drag-drop-sec .wrapper .inner-part .right-section {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 1550px) {
  .fixed_width_content .card {
    max-width: 1050px;
  }

  .fixed_width_content .rpa_para {
    overflow-wrap: break-word;
    inline-size: 100%;
  }

  .responsive_table {
    white-space: unset !important;
  }
}

@media screen and (max-width: 500px) {
  .imgcenter {
    margin-left: 18rem;
  }
}

@media screen and (max-width: 420px) {
  .imgcenter {
    margin-left: 15rem;
  }

  .addnewuser-button {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .imgcenter {
    margin-left: 14rem;
  }
}

@media screen and (max-width: 375px) {
  .imgcenter {
    margin-left: 12rem;
  }
}

@media screen and (max-width: 337px) {
  .imgcenter {
    margin-left: 10rem;
  }
}

@media screen and (max-width: 320px) {
  .imgcenter {
    margin-left: 8rem;
  }
}

.DA-Progress {
  height: 10px;
  border-radius: 5px;
  align-items: center;
}

.DA-Progress .progress-bar {
  border-radius: 5px !important;
  /* margin-left: 5px; */
  height: 20px;
  background-color: #232446;
}

.data-analyzer-bar .progress-bar {
  font-size: 14px;
  padding: 3px;
}

.Add-Script-table-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer {
  justify-content: center !important;
}

.modal-body {
  padding:  1px 1rem !important;   /*changes*/
}

/* Change in swal service */
.swal2-backdrop-show {
  z-index: 99999 !important;
}

.table-title p {
  display: flex;
  gap: 0.7rem;
  text-align: center;
  font-size: medium;
}

.table-pagination {
  align-items: center;
}

.active-button {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 600;
  border: unset;
}

.pagination-input {
  width: 80px;
  text-align: center;
  border: none;
}

button {
  height: 35px;
  width: 0px;
  border: 1.5px solid var(--black-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table_pagination li {
  list-style-type: none;
}

.table_pagination {
  display: flex;
  list-style-type: none;
}

.table_paginations {
  position: relative;
  left: 10px;
}

.pagination_svg svg {
  position: relative;
  top: -3px;
}

.swal2-actions button {
  padding: 7px 47px 31px 21px !important;
}

.ServerChekbox {
  position: relative;
  top: 1.8rem;
}

.mt-checkbox {
  margin-top: 37px;
}

/* Request History List Page Start */

.top-search-add-button-sec {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 0;
}

.top-search-add-button-sec .left-searchbar-sec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}

.top-search-add-button-sec .left-searchbar-sec .form-group {
  margin-bottom: 0;
}

.top-search-add-button-sec .right-button-sec {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-search-add-button-sec .right-button-sec a {
  text-decoration: none;
  padding: 0 25px;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.top-search-add-button-sec .right-button-sec a span {
  margin-left: 10px;
  font-size: 20px;
}

/* Request History List Page End */
.loginbody:before,
.loginbody:after {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  height: 327px;
  width: 692px;
  opacity: 0.3;
}

.loginbody:before {
  background: url(../public/login-bg-shap-1.png);
  top: 0;
  left: 0;
}

.loginbody:after {
  background: url(../public/login-bg-shap-2.png);
  bottom: 0;
  right: 0;
}


.auto-sync-model .form-group {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0 !important;
}


/* .detail-desc label:nth-child(1){
  min-width: 150px;
} */
/* 
.moment-title {
    min-width: 380px;
} */

.activity_details {
  position: relative;
  top: 0rem;
}

.useractivitydetails,
.useractivitydetailsException span {
  word-break: break-all !important;
}

.useractivitydetails,
.useractivitydetailsException {
  background-color: #f7fdff;
  border: 1px solid #dcf6ff;
}

.useractivitydetailsException label {
  font-weight: 600;
  white-space: pre;
}

.exception-input {
  width: 100%;
}

.exception-input.form-group {
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 5px !important;

}

.exception-input .form-group .form-control-label {
  display: none !important;
}

.log-filter .form-group .form-control-label {
  display: none !important;
}

/* .exception-list table thead tr th td div,
.exception-list table tbody tr td {
    width: 10%;
} */
.exception-list td:nth-child(3) {
  word-break: break-all !important;
  white-space: normal;
  width: 20% !important;
}

.filemanager .card {
  overflow: unset !important;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #222344;
}

.drp-buttons .applyBtn {
  width: 60px;
  background-color: #222344;
  border: #222344;
}

.drp-buttons .cancelBtn {
  display: none;
  background-color: #222344;
  border: #222344;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color: #222344;
  border: 0;
  outline: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.actions-icon {
  color: #000000;
  cursor: pointer;
}

.group-req-min-width {
  width: 500%;
}

.cursor-poniter {
  cursor: pointer;
}

.req-group-btn {
  min-width: 50px;
  margin: 0;
  padding: 0;
}

.rpa-req-width {
  min-width: 110px;
}

.pro-import-btn {
  min-width: 220px;
}

.font-size-20 {
  font-size: 20px !important;
  min-width: 20px !important;
}

.font-size-30 {
  font-size: 20px !important;
  min-width: 30px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.link-update label {
  display: none;
}

.version-list {
  min-width: 187px !important;
}

.text-decoration {
  color: #232446 !important;
  font-weight: bold !important;
}

.undeline {
  text-decoration: underline;
}

.min-width-250 {
  min-width: 200px !important;
}

.strong-min-width strong {
  min-width: 20% !important;
}

.scrolling-model .modal-dialog {
  max-width: 50%;
}

.RequestDetails-modal-file {
  width: 100%;
  height: calc(100vh - 250px);
  overflow: hidden;
  overflow-y: auto;
}

.card-main-width .inner-detail-sec .detail-title {
  font-size: 16px;
  font-weight: bold;
  min-width: 230px;
  margin-right: 5px;
  position: relative;
  display: inline-block;
}