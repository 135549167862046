//Main-Rpa_request Start 8

.main-rpa-request {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 15%;
                    }

                    &:nth-child(2) {
                        width: 15%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                
                display: flex;
              
          
                td {
                    padding: 10px 10px 10px 19px;
                    text-align: center;
                    &:nth-child(1) {
                        width: 15%;
                        white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 15%;
                        white-space: normal;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        text-align: center !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        white-space: normal;
                        text-align: center !important;
                        
                    }
                    &:nth-child(5) {
                        width: 10%;
                        white-space: normal;
                        text-align: center !important;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center !important;
                        span{
                            min-width: 80px;
                        }
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        white-space: normal;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
    .no-record-width {
        width: 130%;
        font-size: 20px;
    }
}

//Main-Rpa_request End

//RPA Request Group Start 5
.rpa-request-group-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        // justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 15%;
                        display: flex;
                        // justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        // justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 15%;
                        display: flex;
                        // justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//RPA Request Group End

//Manage Link Builder Start 4
.Link-Buider-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Manage Link Builder End

//Data Analyzer Strat 8
.data-analyzer-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    // padding-left: 5px !important;
                    border: none;
                    &:nth-child(1) {
                        width: 30%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(4) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(6) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;

                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    // padding-left: 5px !important;
                    &:nth-child(1) {
                        width: 30%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(4) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(6) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        // display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                        word-wrap: normal !important;
                    }
                }
            }
        }
    }
}
//Data Analyzer End

//Sync data Start 5
.product-sync-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 7%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 8%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                        white-space: normal !important;
                        word-break: break-all;
                    }

                    &:nth-child(2) {
                        width: 7%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        white-space: normal !important;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                    }
                    &:nth-child(8) {
                        width: 8%;
                        display: flex;
                        justify-content: center;
                       
                    }
                }
            }
        }
    }
    .rounded-pill{
        height: fit-content;
      }
}
//Sync data End

//product-import-list Start 3
.product-import-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//product-import-list End

//Manage Regulation Request Start 4
.regulation-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Manage Regulation Request End

//regulations-list start 7
.regulations-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        white-space: normal !important;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//regulations-list end

//Manage Linkup Request Start 4
.Linkup-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Manage Linkup Request End

//Rpa Module Start 5
.rpa-module-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Rpa Module End

//Server Client Configurations 6
.server-client-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Server Client Configurations End

//Code Management 5
.code-managment-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Code Management End

//Manage Users 6
.manage-user-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Manage Users End

//Manage Server 5
.manage-Server-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                        white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Manage Server End

//Exception 6
.exception-user-list {
    margin-top: 10px;
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 15%;
                    }
                    &:nth-child(3) {
                        width: 35%;
                    }
                    &:nth-child(4) {
                        width: 30%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 15%;
                    }
                    &:nth-child(3) {
                        width: 35%;
                        white-space: normal !important;
                    }
                    &:nth-child(4) {
                        width: 30%;
                        white-space: normal !important;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Exception End

//Email Validation 6
.email-validation-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 25%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Email Validation End

//File-Manager

.file-manager-table {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 15%;
                    }

                    &:nth-child(2) {
                        width: 65%;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 15%;
                        // white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 65%;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

//group-table strat
.group-table {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(3) {
                        padding-left: 0;
                        width: 20%;
                    }
                    &:nth-child(4) {
                        padding-left: 0;
                        width: 10%;
                    }
                    &:nth-child(5) {
                        padding-left: 0;
                        width: 10%;
                    }
                    &:nth-child(6) {
                        padding-left: 0;
                        width: 10%;
                    }
                    &:nth-child(7) {
                        padding-left: 0;
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }

                    &:nth-child(3) {
                        width: 20%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // span {
                        //     position: relative;
                        // }
                    }
                }
            }
        }
    }
}

//group-table End

.bg-yellow {
    background-color: #17a374 !important;
}
.bg-orange {
    background-color: #e02cf2 !important;
}

.request-td-span {
    position: relative;
    .card-tooltip {
        position: absolute;
        background: #dbdcf0;
        box-shadow: 0px 0px 10px -4px #232446;
        // display: none;
        right: 125%;
        top: -10px;
        min-width: 220px;
        border-radius: 5px;
        color: #000;
        padding: 10px;
        ul {
            margin-bottom: 0;
            padding-left: 20px;
        }
        &::after {
            border-bottom: 12px solid #dbdcf0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            content: "";
            display: inline-block;
            right: -15px;
            top: 15px;
            position: absolute;
            transform: rotate(90deg);
        }
        &.visible {
            display: block;
        }
        .cross-icon {
            position: absolute;
            top: -12px;
            right: -20px;
            background: #232446;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    // &:hover {
    //     .card-tooltip {
    //         display: block;
    //     }
    // }
}

.add-icon-space {
    min-width: 15px;
}

.regulation-card {
    // background: #000000a6;
    // height: 100%;
    // width: 100%;
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 999999;
    // display: inline-block;
    // right: unset;
    // bottom: unset;

    .card-regulation-popup {
        padding: 20px;
        background: #dbdcf0;
        top: -333px;

        // &::after {
        //     border-bottom: 12px solid #dbdcf0;
        //     border-left: 12px solid transparent;
        //     border-right: 12px solid transparent;
        //     content: "";
        //     display: inline-block;
        //     right: 25%;
        //     top: 100%;
        //     position: absolute;
        //     transform: rotate(180deg);
        // }
        .regulation-close {
            position: absolute;
            top: 52%;
            right: 29px;
            background: #232446;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

//product-details-grid strat
.product-details-grid {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(3) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(4) {
                        padding-left: 0;
                        width: 20%;
                    }
                    
                    &:nth-child(5) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        padding-left: 0;
                    }

                    &:nth-child(3) {
                        width: 20%;
                        padding-left: 0;
                    }
                  
                    &:nth-child(4) {
                        width: 20%;
                    }
                    &:nth-child(5) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        
                    }
                }
            }
        }
    }
}

//product-details-grid End

//Supplier Comparison strat
.Supplier-Comparison-List {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(3) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(4) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(5) {
                        padding-left: 0;
                        width: 10%;
                    }
                    
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(3) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(4) {
                        padding-left: 0;
                        width: 20%;
                    }

                    &:nth-child(5) {
                        padding-left: 0;
                        width: 10%;
                    }
                    
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

//Supplier Comparison End

.product-import-details{
    color: #fff;
    border: 1px Solid #fff;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
}

.tooltipp {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Define the tooltip text style */
.tooltipp .tooltipptext {
    visibility: hidden;
    background-color: #232446;
    /* Tooltip background color */
    color: #fff;
    /* Tooltip text color */
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    font-size: 12px;
    z-index: 1;
    // bottom: 25px;
    top: 26px;
    left: 155%;
    width:500px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: -5px;
    //     left: 16%;
    //     right: 100%;
    //     margin-top: -5px; /* Adjust this value to position the arrow vertically */
    //     border-width: 5px;
    //     border-style: solid;
    //     border-color: transparent;
    //     border-right-color: #0F1E35; /* Match tooltip background color */
    //     transform: rotate(90deg);
    // }
}

/* Show the tooltip on hover */
.tooltipp:hover .tooltipptext {
    visibility: visible;
    opacity: 1;
}

.RequestDetails-modal-file{
    .pg-viewer-wrapper{
         height:500px !important;
  
     }
     .react-grid-Grid{
         min-height:500px;
     }
     .react-grid-Header{
         height:500px;
     }
     .react-grid-Canvas{
         height: 500px;
     }
    
 }
//  @media only screen and (max-width: 1980px){
//      .RequestDetails-modal-file{
//          .pg-viewer-wrapper{
//               height: 650px !important;
      
//           }
//           .react-grid-Grid{
//               min-height:650px;
//           }
//           .react-grid-Header{
//               height:650px;
//           }
//           .react-grid-Canvas{
//               height: 650px;
//           }
          
//       }
//  }
// Image management start
.image-managment-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: white !important;
            background-color: #232446 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    border: none;
                    &:nth-child(1) {
                        width: 15%;
                    }

                    &:nth-child(2) {
                        width: 15%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 260px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding: 10px 10px 10px 19px;
                    &:nth-child(1) {
                        width: 15%;
                        white-space: normal !important;
                    }

                    &:nth-child(2) {
                        width: 15%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 15%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//image Management End